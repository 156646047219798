import { gql } from "@apollo/client";

export const GET_ESTATES = gql`
  query ($page: Int!, $query: String, $sort: String, $lang: String!) {
    estate(page: $page, query: $query, sort: $sort) {
      data {
        id
        status(asString: true)
        status_id: status
        title
        updated_at
        published_at
        object_type_id(asString: true)
        user {
          phone
        }
      }
      total
    }
    listStatus: list(method: "Estate::listStatus", lang: $lang) {
      id
      name
    }
    objectType {
      id
      nameString(lang: $lang)
    }
    badge
  }
`;

export const GET_ESTATE = gql`
  query ($id: Int!, $lang: String!) {
    estate(id: $id) {
      data {
        id
        object_type_id(asString: true)
        complex {
          id
          nameString(lang: $lang)
        }
        user_type(asString: true)
        has_agent_request
        target
        estate_type(asString: true)
        user {
          id
          phone
          email
          name
          email_verified
          bonus_balance
          balance
        }
        address
        price
        currency_id
        ipoteka
        sale_type
        video
        status
        city_id {
          id
          name {
            value
            lang
          }
        }
        area_id {
          id
          name {
            value
            lang
          }
        }
        place_id {
          id
          name {
            value
            lang
          }
        }
        lat
        lng
        title
        phone
        price_by_meter
        kupchaya
        properties {
          id
          property {
            name {
              lang
              value
            }
            type
            id
            children {
              nameString(lang: $lang)
              id
            }
            dictionary {
              options {
                value: id
                label: valueString(lang: $lang)
              }
            }
          }
          value
          valueRaw
        }
        reject_reason
        comment
        updated_at
        published_at
        created_at
        images {
          id
          url
          is_plan
          is_index
        }
        description {
          lang
          value
        }
        others {
          object_type_id(asString: true)
          status
          id
          published_at
        }
        last_step
        device_type
        histories {
          created_at
          status
        }
        type_rent
        before_month
        pledge
        communal
      }
    }
    objectType {
      id
      nameString(lang: $lang)
    }
    list(method: "Estate::listStatus", lang: $lang) {
      id
      name
    }
    listTypeRent: list(method: "Estate::listTypeRent", lang: $lang) {
      id
      name
    }
    listCommunal: list(method: "Estate::listCommunal", lang: $lang) {
      id
      name
    }
    estateRejectReason {
      id
      title {
        lang
        value
      }
    }
  }
`;

export const GET_ESTATE_REJECT_REASONS = gql`
  query {
    estateRejectReason {
      id
      title {
        lang
        value
      }
    }
  }
`;

export const GET_ESTATE_REJECT_REASON = gql`
  query ($id: Int!) {
    estateRejectReason(id: $id) {
      id
      title {
        lang
        value
      }
      text {
        lang
        value
      }
    }
  }
`;

export const GET_ANALYTIC_ESTATE = gql`
  query ($id: Int!) {
    estateAnalytic(id: $id)
  }
`;

import { gql } from "@apollo/client";

export const EDIT_PROPERTY_GROUP = gql`
  mutation (
    $id: Int!
    $name: String!
    $name_system: String!
    $step_id: Int!
    $show_in_page: Int!
    $type: Int!
  ) {
    updatePropertyGroup(
      id: $id
      name: $name
      name_system: $name_system
      step_id: $step_id
      show_in_page: $show_in_page
      type: $type
    )
  }
`;

export const ADD_PROPERTY_GROUP = gql`
  mutation (
    $name: String!
    $name_system: String!
    $step_id: Int!
    $show_in_page: Int!
    $type: Int!
  ) {
    addPropertyGroup(
      name: $name
      name_system: $name_system
      step_id: $step_id
      show_in_page: $show_in_page
      type: $type
    )
  }
`;

export const DELETE_PROPERTY_GROUP = gql`
  mutation ($id: Int!) {
    deletePropertyGroup(id: $id) {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      step {
        id
        name {
          lang
          value
        }
      }
    }
  }
`;
